import React, { useState, useEffect, useRef } from 'react';

import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // Importe la locale française
import 'react-datepicker/dist/react-datepicker.css';

import UserManage from "../components/Modale/UserManage";
import DealerService from "../services/DealerService";
import Layout from "../components/Layout";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import UserService from "../services/UserService";
import TimelineService from "../services/TimelineService";
import {ExportToExcel} from '../components/ExportToExcel/ExportToExcel'
import Loader from "react-loader-spinner";
// import _ from "lodash";
import Select from "react-select";

import { Space, Table, Tag, ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import statsService from "../services/StatsService";

// Enregistre la locale française
registerLocale("fr", fr);

// Définit la locale par défaut
setDefaultLocale("fr");

const Customerboard = (props) => {
    const [showModal, setShowModal] = useState(false);

    // Three modal types = "new" or "resend" or "revoke"
    const [modalType, setModalType] = useState('');

    /* Datas from API CAll */
    const [datas, setDatas] = useState([]);
    const [dealerList, setDealerList] = useState([]);
    const [onboarders, setOnboarders] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);

    /* Local data used for display */
    const [tableDatas, setTableDatas] = useState([]);
    const [tableDatasRender, setTableDatasRender] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const filtersCustomerboard = localStorage.getItem('filtersCustomerboard');

    /* 6 Filters + intervention filter */
    const [searchFilter, setSearchFilter] = useState(filtersCustomerboard ? JSON.parse(filtersCustomerboard).searchFilter : '');
    const [searchPlateFilter, setSearchPlateFilter] = useState(filtersCustomerboard ? JSON.parse(filtersCustomerboard).searchPlateFilter : '');
    const [activeFilter, setActiveFilter] = useState(filtersCustomerboard ? JSON.parse(filtersCustomerboard).activeFilter : '');
    const [onboardedFilter, setOnboardedFilter] = useState(filtersCustomerboard ? JSON.parse(filtersCustomerboard).onboardedFilter : '');
    const [dealerFilter, setDealerFilter] = useState(filtersCustomerboard ? JSON.parse(filtersCustomerboard).dealerFilter : '');
    const [dateRangeFilter, setDateRangeFilter] = useState(filtersCustomerboard ? (JSON.parse(filtersCustomerboard).dateRangeFilter ? JSON.parse(filtersCustomerboard).dateRangeFilter.map(dateString => new Date(dateString)) : '') : '');
    const [interventionFilter, setInterventionFilter] = useState(filtersCustomerboard ? JSON.parse(filtersCustomerboard).interventionFilter : '');

    const [loader, setLoader] = useState(true);
    const history = useHistory();
    const role = localStorage.getItem('role');
    const email = localStorage.getItem('email');
    const dealer = localStorage.getItem('dealer');
    const dealerGroup = localStorage.getItem('dealerGroup');
    const [entity, setEntity] = useState(null);

    /* Stats */
    const [users, setUsers] = useState(0);
    const [webOnboardedUsers, setWebOnboardedUsers] = useState(0);
    const [dealerOnboardedUsers, setDealerOnboardedUsers] = useState(0);
    const [activatedUsers, setActivatedUsers] = useState(0);
    const [usersWithVin, setUsersWithVin] = useState(0);
    const [deletedUsers, setDeletedUsers] = useState(0);
    /* /stats */

    const isInitialMount = useRef(true);

    const { t } = useTranslation();

    const columns = [
      {
        title: t('Name'),
        className: 'firstColName',
        dataIndex: 'nom',
        render: (text) => <><span className="ellipsis" title={text[0]}>{text[0]}</span><br/><span className="legend ellipsis" title={text[1]}>{text[1]}</span>{text[2] === '' ? '' : <div><span className="inactive ellipsis">{text[2]}</span></div> }</>,
        sorter: (a, b) => a.nom[0].localeCompare(b.nom[0]),
      },
      {
        title: 'Membre depuis',
        dataIndex: 'memberSince',
        render: (text) => <>{moment(text).format('DD/MM/YYYY')}</>,
        sorter: (a, b) => a.memberSince.localeCompare(b.memberSince),
      },
      {
        title: 'Créé par',
        dataIndex: 'createdBy',
        sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
      },
      {
        title: 'CRE',
        dataIndex: 'cre',
        render: (text) => <>{text[0]} /<br/>{text[1]}<br/><span className="legend">{text[2]}</span></>,
        sorter: (a, b) => a.cre[0].localeCompare(b.cre[0]),
      },
      {
        title: 'Plaque Immat / VIN',
        dataIndex: 'plaqueImmat',
        render: (text) => <>{text[0]}<br/><span className="legend">{text[1]}</span></>,
        sorter: (a, b) => a.plaqueImmat[1].localeCompare(b.plaqueImmat[1]),
      },
      {
        title: 'Dernière connexion',
        dataIndex: 'lastConnexion',
        render: (text) => <>{text === '-' ? text : moment(text).format('DD/MM/YYYY')}</>,
        sorter: (a, b) => a.lastConnexion.localeCompare(b.lastConnexion),
      },
      {
        title: 'Connexions',
        className: 'text-center px-2',
        dataIndex: 'connexions',
        sorter: (a, b) => a.connexions - b.connexions,
        // render: (_, { tags }) => (
        //   <>
        //     {tags.map((tag) => {
        //       let color = tag.length > 5 ? 'geekblue' : 'green';
        //       if (tag === 'loser') {
        //         color = 'volcano';
        //       }
        //       return (
        //         <Tag color={color} key={tag}>
        //           {tag.toUpperCase()}
        //         </Tag>
        //       );
        //     })}
        //   </>
        // ),
        onHeaderCell: () => {
          return {
            'data-after-title': '12 derniers mois',
          };
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        // render: (_, record) => (
        //   <Space size="middle">
        //     <a>Invite {record.name}</a>
        //     <a>Delete</a>
        //   </Space>
        // ),
      },
    ];

    // console.log('interventionFilter', interventionFilter);

    useEffect(() => {
        let entity = '';
        if (role.includes('ROLE_ADMIN'))
            entity = "Admin";
        else if (role.includes('ROLE_DEALER_DIRECTOR'))
            entity = 'Groupe ' + localStorage.getItem('dealerGroup');
        else
            entity = dealerGroup + " / " +  localStorage.getItem('dealerName');

        setEntity(entity);
    }, []);

    useEffect(() => {
        TimelineService.getEventTypes({
            token: localStorage.getItem('token')
        }).then((res) => {
                //console.log(res.data.data)
                setEventTypes(res.data.data);
            }
        )
    }, []);

    useEffect(() => {
        // console.log('useEffect dealer dealerGroup', dealerGroup);
        /* Verify roles */
        if (role === null) {
            localStorage.clear();
            history.push('/login');
        } else if (role && !role.includes('ROLE_DEALER') && !role.includes('ROLE_ADMIN')) {
            history.push('/login');
        }

        // init filters
        let filter = ''
        let id = ''

        if (dealer === null)
            return

        // getDealerShipUsers
        if (role && !role.includes('ROLE_ADMIN')) {
            filter = 'group'
            id = dealerGroup
        }

        DealerService.getDealerShipUsers({
            token:  localStorage.getItem('token'),
            email:  localStorage.getItem('email'),
            filter: filter,
            id:     id
        })
        .then((response) => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
                if (interventionFilter !== '') {
                  //console.log('useEffect interventionFilter', interventionFilter);
                  interventionFilterChange(interventionFilter, false);
                } else {
                    setDatas(response.data.users);
                    setLoader(false);
                }
            } else {
                setDatas(response.data.users);
                setLoader(false);
            }

        })
        .catch((error) => {
            console.log('erreur customerboard', error);
            if (error.toString().includes("401")) {
                localStorage.clear();
                history.push('/login');
            }
        });
    }, [dealer, dealerGroup]);

    useEffect(() => {
        if (!isInitialMount.current) {
            console.log('useEffect globalFilter');
            globalFilter(searchFilter, searchPlateFilter, activeFilter, onboardedFilter, dealerFilter, dateRangeFilter);
        }
    }, [datas]);

    useEffect(() => {
        if (!isInitialMount.current) {
            console.log('useEffect filtersCustomerboard');

            localStorage.setItem('filtersCustomerboard', JSON.stringify({
                searchFilter: searchFilter,
                searchPlateFilter: searchPlateFilter,
                activeFilter: activeFilter,
                onboardedFilter: onboardedFilter,
                dealerFilter: dealerFilter,
                dateRangeFilter: dateRangeFilter,
                interventionFilter: interventionFilter
            }));
        }
    }, [searchFilter, searchPlateFilter, activeFilter, onboardedFilter, dealerFilter, dateRangeFilter, interventionFilter]);

    useEffect(() => {
        DealerService.getDealers({
            token: localStorage.getItem('token') ,
            groupName: role && !role.includes('ROLE_ADMIN') ? dealerGroup : null
        })
        .then((response) => {
            let activeDealers = response.data['dealers'];
            setDealerList(activeDealers);
        })
        .catch((error) => {
            console.log(error);
            if (error.toString().includes("401")) {
                localStorage.clear();
                history.push('/login');
            }
        });

        UserService.getOnboarders({ token: localStorage.getItem('token') })
        .then((response) => {
            // console.log(response.data);
            setOnboarders(response.data.onboarders)
        })
        .catch((error) => {
            console.log(error);
            if (error.toString().includes("401")) {
                localStorage.clear();
                history.push('/login');
            }
        });

    },[])

    useEffect(() => {
        let filter = null;
        let id = null;

        // getDealerShipUsers
        if (role && !role.includes('ROLE_ADMIN')) {
            filter = 'group'
            id = dealerGroup
        }

        statsService.getCustomerboardStats({
            token: localStorage.getItem('token'),
            filter: filter ?? null,
            id: id ?? null
        })
            .then((response) => {
                const data = response.data['stats'];
                setUsers(data['inscriptions']['total']);
                setWebOnboardedUsers(data['inscriptions']['web']);
                setDealerOnboardedUsers(data['inscriptions']['onboarded']);
                setActivatedUsers(data['activations']);
                setUsersWithVin(data['added_vin']);
                setDeletedUsers(data['deletion']);
            })
            .catch((error) => {
                console.log('error with getCustomerboardStats', error);
            });
    }, []);

    const dateSubscriptionPicker = () => {
        // console.log('dateSubscriptionPicker', dateRangeFilter);
        const [startDate, endDate] = dateRangeFilter || [null, null];
        const dateDuJour = new Date();
        // console.log('startDate', startDate);
        return (
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                className={"form-control fs-13"}
                placeholderText="Période"
                minDate={new Date(2022, 8, 20)}
                maxDate={dateDuJour}
                onChange={(update) => {
                    if (update[0] === null && update[1] === null) {
                      setDateRangeFilter('');
                    } else {
                      setDateRangeFilter(update);
                    }
                    globalFilter(searchFilter, searchPlateFilter, activeFilter, onboardedFilter, dealerFilter, update);
                }}
                isClearable={true}
            />
        );
    };

    const dealershipFilterOptionsMulti = () => {

        if(dealerList.length === 0 )
            return

        let dealerListByGroup = [];

        dealerList.forEach((dealer) => {
            if(dealer.dealerGroup !== undefined) {
                (dealerListByGroup[dealer.dealerGroup] = dealerListByGroup[dealer.dealerGroup] || []).push(dealer)
            }
        })

        const groupedOptions = [];

        for (const group in dealerListByGroup) {
            if (dealerListByGroup.hasOwnProperty(group)) {
                const value = dealerListByGroup[group];
                const arrayValue = [];

                value.forEach((dealer) => {
                    arrayValue.push({value: dealer.id, label: dealer.name + ' (' + dealer.dealerCode + ')'})
                });

                groupedOptions.push({
                    label: group,
                    options: arrayValue
                });
            }
        }

        groupedOptions
        .sort((a, b) =>{
            if(a.label < b.label) { return -1; }
            if(a.label > b.label) { return 1; }
            return 0;
        });

        return groupedOptions;

    }

    /* Filter methods  */
    const searchFilterChange = (e) => {
        setSearchFilter(e.target.value);
        globalFilter(e.target.value, searchPlateFilter, activeFilter, onboardedFilter, dealerFilter, dateRangeFilter);
    }

    const searchPlateFilterChange = (e) => {
        //console.log('searchPlateFilterChange', e.target.value);
        setSearchPlateFilter(e.target.value);
        globalFilter(searchFilter, e.target.value, activeFilter, onboardedFilter, dealerFilter, dateRangeFilter);
    }

    const interventionFilterChange = (value, shouldSetFilter = true) => {

        // console.log('interventionFilterChange', value);
        if (shouldSetFilter) {
          setInterventionFilter(value);
        }

        showContent(false);
        // setDatas([]);
        // setTableDatas([]);

        if (value==="all" || value==="") {
            // init filters
            let filter = ''
            let id = ''

            if (dealer === null)
                return

            // getDealerShipUsers
            if (role && !role.includes('ROLE_ADMIN')) {
                filter = 'group'
                id = dealerGroup
            }
            DealerService.getDealerShipUsers({
                token: localStorage.getItem('token'),
                email: localStorage.getItem('email'),
                filter: filter,
                id: id
            })
            .then((response) => {
                showContent(true);
                //console.log('setDatas from filter intervention all');
                setDatas(response.data.users);
                //setTableDatas(response.data.users);
                setLoader(false);
            })
            .catch((error) => {
                console.log('erreur customerboard', error);
                if (error.toString().includes("401")) {
                    localStorage.clear();
                    history.push('/login');
                }
            });
        } else {
            TimelineService.getUsersForEvent({
                eventType: value,
                email: localStorage.getItem('email'),
                token: localStorage.getItem('token')
            })
            .then((response) => {
                //console.log('setDatas from filter intervention TimelineService', response.data.salesPersons.length);
                showContent(true);
                setDatas(response.data.salesPersons);

                setLoader(false);
                //setTableDatas(response.data.salesPersons.filter(generalFilter));
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    const activeFilterChange = (e) => {
        setActiveFilter(e.target.value);
        globalFilter(searchFilter, searchPlateFilter, e.target.value, onboardedFilter, dealerFilter, dateRangeFilter);
    }

    const onboardedFilterChange = (e) => {
        setOnboardedFilter(e.target.value);
        globalFilter(searchFilter, searchPlateFilter, activeFilter, e.target.value, dealerFilter, dateRangeFilter);
    }

    const dealershipMultiFilterChange = (e) => {
        if(e.length) {
            setDealerFilter(e);
            globalFilter(searchFilter, searchPlateFilter, activeFilter, onboardedFilter, e, dateRangeFilter);
        }else{
            setDealerFilter(null);
            globalFilter(searchFilter, searchPlateFilter, activeFilter, onboardedFilter, null, dateRangeFilter);
        }
    }

    const resetForm = (e) => {
        setDealerFilter(null);
        setSearchPlateFilter('');
        setSearchFilter('');
        setActiveFilter('');
        setOnboardedFilter('');
        setDealerFilter('');
        setDateRangeFilter('');
        interventionFilterChange('');
        document.getElementById('search-form').reset();
    }

    /*
     * Filtering with all the filters
     */
    const globalFilter = (searchFilterParams= "", searchPlateFilterParams= "", activeFilterParams= "", onboardedFilterParams = "", dealerFilterParams = "", dateRangeFilterParams = "") => {
        let generalFilter = data => {
            if(searchFilterParams && (!(data.first_name+ ' '+data.last_name).toLowerCase().includes(searchFilterParams.toLowerCase()) && !(data.email).toLowerCase().includes(searchFilterParams.toLowerCase()))) {
                return false;
            }

            // When licence plate is null, don't display on search
            if(data.licence_plate == null && searchFilterParams == '' && activeFilterParams =='' && onboardedFilterParams == '' && dealerFilterParams == '' && dateRangeFilterParams == ''){
                return false;
            }

            if(searchPlateFilterParams) {
                if (data.licence_plate ==  null && data.vin == null) {
                    return false;
                } else if (data.licence_plate == null) {
                    if (!(data.vin).toUpperCase().includes(searchPlateFilterParams.toUpperCase())) {
                        return false;
                    }
                } else if (data.vin == null) {
                    if (!(data.licence_plate).toUpperCase().includes(searchPlateFilterParams.toUpperCase())) {
                        return false;
                    }
                } else {
                    if (!(data.licence_plate).toUpperCase().includes(searchPlateFilterParams.toUpperCase()) && !(data.vin).toUpperCase().includes(searchPlateFilterParams.toUpperCase())) {
                        return false;
                    }
                }
            }

            let validationDate = data.validation_date !== null ? "active" : "inactive";

            if(activeFilterParams && activeFilterParams !== 'all' && validationDate !== activeFilterParams) {
                return false;
            }

            if( onboardedFilterParams && onboardedFilterParams !== 'all' &&
                (
                  (
                      onboardedFilterParams === '1'
                      && data.onboarded_by == null
                  )
                  || (
                      onboardedFilterParams === '0'
                      && data.onboarded_by != null
                  )
                )
            ) {
                return false;
            }

            if (dealerFilterParams && !dealerFilterParams.some(obj => obj.value === data.dealer_id)) {
                return false;
            }

            const dateSubscription = new Date(data.subscription_date);

            if (dateRangeFilterParams && dateRangeFilterParams[0] != null && dateRangeFilterParams[1] != null && (dateSubscription.getTime() < dateRangeFilterParams[0].getTime() || dateSubscription.getTime() > dateRangeFilterParams[1].getTime())) {
                return false;
            }

            return true;
        }

        // console.log('globalFilter nb result', datas.length);
        //When nothing to filter, display all
        if(searchFilterParams == '' && searchPlateFilterParams == '' && activeFilterParams =='' && onboardedFilterParams == '' && dealerFilterParams == '' && dateRangeFilterParams == '') {
          console.log('all filters empty');
          // le filtre est vide, on affiche tout
          generalFilter = data => {
            return true;
          }

        }

        let tempDatas = datas.filter(generalFilter);

        // use for export excel
        setTableDatas(tempDatas);

        let dataRender = [];


        tempDatas.map((data, i) => {

          let dealerGroupTemp = (dealerList.length > 0
              && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0])
            && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0].dealerGroup;

          let inactive = data.old_dealer !== 1 && data.validation_date !== null ? '' : t('Inactive');

          dataRender.push({
            nom: [`${data.first_name} ${data.last_name}`, data.email, inactive],
            memberSince: data.subscription_date,
            createdBy: data.onboarded_by ? data.onboarded_by : 'Web',
            cre: [dealerGroupTemp ? dealerGroupTemp : '', (dealerList.length > 0
              && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0])
            && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0].name, (dealerList.length > 0
              && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0])
            && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0].dealerCode],
            plaqueImmat: [data.licence_plate, data.vin ? data.vin : '-'],
            lastConnexion: data.last_connection_date ? data.last_connection_date : '-',
            connexions: data.connexions ? data.connexions : 0,
            action: actions(data.validation_date, data),
            key: i,
            // connexions: `London, Park Lane no. ${i}`,
            // tags: ['nice', 'developer'],
          });
        });

        setTableDatasRender(dataRender);
    }

    const sendConfirmCustomerMail = (user) => {
        //console.log(user);
        UserService.sendConfirmCustomerEmail({
            "email": user.email,
            "lang": user.language
        }).then((res) => {
            history.push({
                pathname: '/reset-link-sent-customer',
                state: { email: user.email }
            })
        }).catch((error) => {
            console.log(error);
        });
    }
    /* /Filter methods */

    /* Display methods
     * status takes old_dealer value from api
     * selectedUser contains all the datas of the current user. It's given to the openModal method when needed
     */

    const actions = (status,selectedUser) => {
        if (status !== null) {
            if (selectedUser.vin != null) {
                return (<span className="text-decoration-none cursor-pointer" title="Voir les informations sur ce client et les évènements sur son véhicule"
                              onClick={event => redirectToTimeline(selectedUser)}><span
                    className="icon-2-address-card fs-20"></span></span>);
            }
        } else {
            return (<span onClick={event => sendConfirmCustomerMail(selectedUser)} className="text-decoration-none dark-grey cursor-pointer fs-13" title="Le client va recevoir un email pour activer son compte dans son espace Nissan le Club e-Carnet"><span className="icon-inbox align-middle"></span> {t('Re-send activation link')}</span>);
        }
    }

    const redirectToTimeline = (selectedUser) => {
        localStorage.setItem('selectedUser', JSON.stringify(selectedUser));
        localStorage.setItem('previousPage', 'customerboard');
        history.push({
            pathname: "/user-timeline/"+selectedUser.vin,
            state: {
                selectedUser: localStorage.setItem('selectedUser', JSON.stringify(selectedUser))
            }
        })
    }

    // TODO : new filter interventions by véhicule
    const interventionFilterOptions = () => {
        return (
            eventTypes && eventTypes.length > 0 &&
            eventTypes
            .map((data, index) => {
                    {
                        let value = data
                        return(
                            <option value={value} key={value}>{value}</option>
                        )}
                }
            )
        )
    }
    /* Display methods */

    /* Modal methods */

    /*
     * modalType = "new", 'resend' or "revoke"
     */
    const openModal = (modalType, user={}) => {
        setShowModal(true);
        setModalType(modalType);
        setSelectedUser(user);
    }

    const handleClose = () => {
        setShowModal(false);
        setSelectedUser({});
    }

    const redirectTo = () => {
        history.push('/user-add-car')
    }

    const getExportToExcel = () => {
        const dateDay = new Date();
        const fileName = 'UsersTimelines_'+moment(dateDay).format('YYYY/MM/DD');

        //console.log('getExportToExcel', tableDatas.length);

        return (
            <div className="App">
                <ExportToExcel exportArray={tableDatas} fileName={fileName} title="Exporter les informations sur les clients et VIN Nissan le Club e-Carnet sélectionnés dans le tableau" />
            </div>
        );

    }

    const showContent = (loader) => {
        // console.log(tableDatas);
        if(loader == true)
        {
            return (
                <div style={{ textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#C0092D"
                        height={100}
                        width={100}
                    />
                </div>
            )
        } else {

            return (
              <div className={"table-responsive"}>
                  <ConfigProvider locale={frFR} theme={{
                      components: {
                        Table: {
                          cellPaddingBlock: '10px',
                        },
                      }, }}>
                    <Table columns={columns} dataSource={tableDatasRender} className={"table table-striped table-align-middle fs-13 table-custom"} />
                  </ConfigProvider>
              </div>
            )
        }
    }

    /* /Modal methods */

    return (
        <Layout>
            <div className="white-container">
              <form id="search-form">
                <div className="row mb-3">
                  <div className="col-md-7 mb-4 mb-md-0">
                    {/*Si on est super admin on affiche que la 1ere valeur / Si on est un group on rajoute la 2eme / Si on est un CRE la 3ème*/}
                    <h2 className="with-border">{t('Dashboard clients')}</h2>
                    <p className="with-border">
                      {email}
                      {entity != '' &&
                        <span><br /> ({entity})</span>
                      }
                    </p>
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-center mb-4 mb-md-0">
                    <button className="button red with-icon fs-13 w-100" onClick={() => redirectTo()} title="Créer un nouveau compte client et/ou lui rattacher un VIN dans la plateforme Nissan le Club e-Carnet"><span className="icon-profile icon-profile-new icon fs-20"></span><span className="pl-4">{t('Add a new client')}</span></button>
                  </div>
                  <div className="col-md-1 fs-30  d-flex align-items-center justify-content-center">
                    {getExportToExcel()}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 col-lg-3">
                    <div className="input-with-icon">
                      <input type="text" value={searchFilter} onChange={searchFilterChange} className="form-control fs-13" placeholder={t('Type a name')} />
                      <span className="icon-search icon"></span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="input-with-icon">
                      <input type="text" value={searchPlateFilter} onChange={searchPlateFilterChange} className="form-control fs-13" placeholder={t('plate number vin')} />
                      <span className="icon-search icon"></span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="select">
                      <select value={interventionFilter} onChange={(event) => interventionFilterChange(event.target.value)} className="form-control fs-13">
                        <option disabled value="">{t('Intervention')}</option>
                        <option value="all">{t('All')}</option>
                        {interventionFilterOptions()}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="select">
                      <select value={activeFilter} onChange={(event) => activeFilterChange(event)} className="form-control fs-13">
                        <option disabled value="">{t('Activation status')}</option>
                        <option value="all">{t('All')}</option>
                        <option value="active">{t('Active')}</option>
                        <option value="inactive">{t('Inactive')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="select">
                      <select value={onboardedFilter} onChange={(event) => onboardedFilterChange(event)} className="form-control fs-13">
                        <option disabled value="">{t('Onboarded by')}</option>
                        <option value="all">{t('All')}</option>
                        <option value="1">{t('onboarding by CRE')}</option>
                        <option value="0">{t('onboarding by Customer')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="">
                      <Select
                        options={dealershipFilterOptionsMulti()}
                        value={dealerFilter}
                        className="react-select-container mb-0 fs-13"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        isMulti={true}
                        placeholder={t('All concessions')}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        formatGroupLabel={({ label }) => (
                          <div>
                            <strong>{label}</strong>
                          </div>
                        )}
                        formatOptionLabel={({ label }) => (
                          <div title={label}>
                            {label}
                          </div>
                        )}
                        onChange={(event) => {
                          dealershipMultiFilterChange(event);
                        }} />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="date">
                      {dateSubscriptionPicker()}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="d-flex align-items-center">
                      <button type="button" className="button white with-icon fs-13 w-100" onClick={() => resetForm()}><span className="icon-error icon"></span><span className="pl-2">{t('Reset')}</span></button>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">

                  <div className="col-md-12 col-xl-10 mb-3 mb-xl-0 d-flex cards-stats">
                    <div className="card card-stats flex-grow-1">
                      <div className="card-body d-flex align-items-center">
                        <h5 className="card-title"><strong>{users}</strong><br />Inscriptions NLC
                          <br /><span className="last-mentions">{webOnboardedUsers} Via le web
                                            <br />{dealerOnboardedUsers} En concession</span></h5>
                      </div>
                    </div>
                    <div className="card card-stats flex-grow-1">
                      <div className="card-body d-flex align-items-center">
                        <h5 className="card-title"><strong>{activatedUsers}</strong><br />Ont activé<br />leur compte</h5>
                      </div>
                    </div>
                    <div className="card card-stats flex-grow-1">
                      <div className="card-body d-flex align-items-center">
                        <h5 className="card-title"><strong>{usersWithVin}</strong><br />Ont ajouté<br />un VIN</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-2 mb-3 mb-xl-0 d-flex">
                    <div className="card card-stats flex-grow-1">
                      <div className="card-body d-flex align-items-center p-0">
                        <h5 className="card-title p-0"><strong>{deletedUsers}</strong><br />Désinscriptions</h5>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-12 text-right fs-13 font-italic font-weight-bold mb-2 dark-grey d-none">
                  {tableDatas.length} résultat{ tableDatas.length > 1 ? 's' : '' }
                </div>

              </form>

              {showContent(loader)}
            </div>

          {showModal &&
            <UserManage dealerList={dealerList} showModal={showModal} handleClose={() => handleClose()} modalType={modalType} selectedUser={selectedUser} />
            }

        </Layout>
    );
}


export default Customerboard
